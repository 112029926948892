import React from "react"

const SectionCard = ({ children, id, title, titleEn }) => {
  return (
    <section className="section is-bg-yellow is-radius my-2 pt-3 " id={id}>
      <h2 className="title-en-ja has-text-centered" data-en={titleEn}>
        <span>{title}</span>
      </h2>
      {children}
    </section>
  )
}

export default SectionCard
