import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { getImage } from "gatsby-plugin-image"

// このコンポーネントはローカルで使う
const ArrowChevron = props => {
  return (
    <FontAwesomeIcon
      className={"slick-arrow-chevron " + props.type}
      icon={props.type === `prev` ? faChevronLeft : faChevronRight}
      onClick={props.onClick}
    />
  )
}

const SliderMenu = ({ menuMds }) => {
  // menuMdは、graphqlから取得する以下のデータ形式
  //     menuMds: allMarkdownRemark(
  //       filter: { frontmatter: { type: { eq: "menu" } } }
  //     ) {
  //       edges {
  //         node {
  //           frontmatter {
  //             title
  //             featuredImage {
  //               childImageSharp {
  //                 gatsbyImageData(placeholder: BLURRED)
  //               }
  //             }
  //           }
  //           html
  //         }
  //       }

  const sliderSettings = {
    centerMode: true,
    slidesToShow: 3,
    centerPadding: "20px",
    slidesToScroll: 1,
    prevArrow: <ArrowChevron type="prev" />,
    nextArrow: <ArrowChevron type="next" />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Slider {...sliderSettings} className="my-5">
      {menuMds.edges.map(({ node }, index) => (
        <div className="card has-menu" key={index}>
          <div className="card-image">
            <figure className="image">
              <GatsbyImage
                image={getImage(node.frontmatter.featuredImage)}
                placeholder="none"
                quality={95}
                alt={node.frontmatter.title}
              />
            </figure>
          </div>
          <div className="card-content">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: node.html }}
            ></div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default SliderMenu
