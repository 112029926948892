import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React from "react"
import Layout from "../components/layout"
import SectionCard from "../components/section-card"
import Seo from "../components/seo"
import SliderMenu from "../components/slider-menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "top.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: NONE)
      }
    }
    heroMd: markdownRemark(frontmatter: { title: { eq: "hero" } }) {
      html
    }
    conceptMd: markdownRemark(frontmatter: { title: { eq: "concept" } }) {
      html
    }
    materialsMd: markdownRemark(frontmatter: { title: { eq: "materials" } }) {
      html
    }
    bakerMd: markdownRemark(frontmatter: { title: { eq: "baker" } }) {
      html
    }
    menuMds: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "menu" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          html
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo />
      <BackgroundImage
        Tag="div"
        {...convertToBgImage(getImage(data.heroImage))}
      >
        {/* ↓の要素のbackgroundが変わるっぽい */}
        <div className="hero-image">
          <StaticImage
            src="../images/logo-white.png"
            placeholder="none"
            quality={90}
            alt="Logo"
            className="logo"
          />
        </div>
      </BackgroundImage>

      <div
        className="hero-message"
        dangerouslySetInnerHTML={{ __html: data.heroMd.html }}
      />

      <div className="scroll-down" />

      <div className="container">
        <SectionCard id="concept" title="こだわり" titleEn="About">
          <h3 className="title-sub has-text-centered">
            <span>お店のコンセプト</span>
          </h3>
          <div className="columns">
            <div className="column is-6" style={{ textAlign: "center" }}>
              <StaticImage
                src="../images/bread-split.jpg"
                placeholder="none"
                alt="concept"
                height={380}
              />
            </div>
            <div
              className="column is-6 concept-article"
              dangerouslySetInnerHTML={{ __html: data.conceptMd.html }}
            ></div>
          </div>

          <h3 className="title-sub has-text-centered">
            <span>材料のこだわり</span>
          </h3>
          <div className="columns">
            <div
              className="column is-6 concept-article"
              dangerouslySetInnerHTML={{ __html: data.materialsMd.html }}
            ></div>
            <div className="column is-6" style={{ textAlign: "center" }}>
              <StaticImage
                src="../images/bread-hard-zoom.jpg"
                placeholder="none"
                alt="materials"
                height={380}
              />
            </div>
          </div>
        </SectionCard>

        <SectionCard id="menu" title="お品書き" titleEn="Menu">
          <p className="mt-3">
            下記ラインナップはいつでも、
            <a
              href="https://shop.haru-hana-tsuki.fun/"
              target="_blank"
              rel="noopener noreferrer"
              className="has-underline"
            >
              <span className="icon-text">
                <span>ネットショップ BASE にてお求めいただけます。</span>
                <span className="icon">
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </span>
              </span>
            </a>
            <br />
            また不定期ですが、イベント等の店頭販売も実施しております。
            <a
              href="https://www.instagram.com/haru_hana_tsuki/"
              target="_blank"
              rel="noopener noreferrer"
              className="has-underline"
            >
              <span className="icon-text">
                <span>最新情報は Instagram をご確認ください。</span>
                <span className="icon">
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </span>
              </span>
            </a>
          </p>
          <SliderMenu menuMds={data.menuMds} />
        </SectionCard>

        <SectionCard id="baker" title="店主" titleEn="Baker">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="card is-bg-transparent">
                <div className="card-image">
                  <figure className="image">
                    <StaticImage
                      src="../images/harumi.jpg"
                      placeholder="none"
                      alt="baker"
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <h1 className="title is-5">Harumi Yoshida</h1>
                  <h2 className="subtitle is-6 mb-0 has-text-black">
                    製菓衛生師/パンシェルジュマスター(1級)
                  </h2>
                  <div
                    className="content mt-2"
                    dangerouslySetInnerHTML={{ __html: data.bakerMd.html }}
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionCard>
      </div>
    </Layout>
  )
}

export default IndexPage
