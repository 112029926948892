/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import Footer from "./footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStore } from "@fortawesome/free-solid-svg-icons"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://shop.haru-hana-tsuki.fun/"
        className="button is-rounded is-info is-hidden-tablet fix-rightup"
      >
        <span className="icon">
          <FontAwesomeIcon icon={faStore} />
        </span>
        <span>お買いもの</span>
      </a>
      {children}
      <Footer />
    </>
  )
}

export default Layout
