import React from "react"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Footer = () => (
  <footer className="footer">
    <div className="content">
      <div className="has-text-centered">
        <p>© 2021 パンとおやつ ハルハナツキ</p>
        <a
          href="https://www.instagram.com/haru_hana_tsuki/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon-text">
            <span className="icon has-text-danger">
              <FontAwesomeIcon icon={faInstagramSquare} />
            </span>
            <span className="has-text-black font-instagram">Instagram</span>
          </span>
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
