import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHandHoldingHeart,
  faBreadSlice,
  faUserCircle,
  faExternalLinkAlt,
  faStore,
} from "@fortawesome/free-solid-svg-icons"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"

const Header = () => {
  return (
    <nav className="navbar is-fixed-top is-transparent">
      <div className="navbar-brand is-fullwidth">
        <AnchorLink
          to="/#concept"
          className="navbar-item is-expanded is-justify-content-center has-text-weight-bold"
        >
          <span className="icon-text">
            <span className="icon">
              <FontAwesomeIcon icon={faHandHoldingHeart} />
            </span>
            <span>こだわり</span>
          </span>
        </AnchorLink>
        <AnchorLink
          to="/#menu"
          className="navbar-item is-expanded is-justify-content-center has-text-weight-bold"
        >
          <span className="icon-text">
            <span className="icon">
              <FontAwesomeIcon icon={faBreadSlice} />
            </span>
            <span>お品書き</span>
          </span>
        </AnchorLink>
        <AnchorLink
          to="/#baker"
          className="navbar-item is-expanded is-justify-content-center has-text-weight-bold"
        >
          <span className="icon-text">
            <span className="icon">
              <FontAwesomeIcon icon={faUserCircle} />
            </span>
            <span>店主</span>
          </span>
        </AnchorLink>
        <a
          href="https://www.instagram.com/haru_hana_tsuki/"
          target="_blank"
          rel="noopener noreferrer"
          className="navbar-item is-expanded is-justify-content-center has-text-weight-bold"
        >
          <span className="icon-text">
            <span className="icon">
              <FontAwesomeIcon icon={faInstagramSquare} />
            </span>
            <span className="font-instagram">Instagram</span>
          </span>
        </a>
        <div className="navbar-item is-expanded is-justify-content-center is-hidden-mobile">
          <a
            href="https://shop.haru-hana-tsuki.fun/"
            target="_blank"
            rel="noopener noreferrer"
            className="button is-info"
          >
            <span className="icon-text">
              <span className="icon">
                <FontAwesomeIcon icon={faStore} />
              </span>
              <span>お買いもの</span>
            </span>
          </a>
        </div>
      </div>
      {/* <div className="header-notify is-bg-red">
      </div> */}
    </nav>
  )
}

export default Header
